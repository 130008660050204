import { createApp } from 'vue'
import App from './App.vue'
import 'material-icons/iconfont/material-icons.css';
import './index.css'

// fa
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faRoad, faPlaneEngines, faBoxOpen, faContainerStorage, faShieldCheck, faLaptop, faCalendarClock, faGlobe, faDiploma,
    faLifeRing, faHeadset, faScreenUsers ,faBrakeWarning } from '@fortawesome/pro-solid-svg-icons'

library.add(faRoad, faPlaneEngines, faContainerStorage, faBoxOpen, faLaptop, faShieldCheck, faLifeRing, faGlobe,
    faDiploma, faCalendarClock, faHeadset, faScreenUsers, faBrakeWarning)

// multiselect component
import Multiselect from "@vueform/multiselect";
require('@vueform/multiselect/themes/default.css')


const Vue = createApp(App)

// router
import router from "@/router";
Vue.use(router)

// analytics
import VueGtag from "vue-gtag"
if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtag, {
        config: { id: "G-EKRBDLJM3Q" }})
}

// register components
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('multi-select', Multiselect)

Vue.mount('#app')
