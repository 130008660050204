<template>
    <div id="app">
        <div class="pt-24 hero bg-fixed bg-center bg-cover" style="min-height: 550px">
            <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center justify-center">
                <div class="flex flex-col w-full justify-center items-center text-center">
                    <p class="uppercase md:mt-8 font-bold text-teal-500 tracking-loose w-full" style="letter-spacing: .02em">
                        March 21-25, 2022
                    </p>
                    <h1 class="mb-8 text-5xl font-medium leading-tight">
                        Live Training Webinars
                    </h1>
                    <p class="leading-normal font-medium text-gray-800 text-2xl mb-8" style="max-width: 680px">
                        Get your initial or recurrent certification for one or more modes of transport including IATA Air, IMDG Ocean, and 49 CFR Ground shipping during
                        our week-long live webinars
                    </p>
                    <!--                  <button class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">-->
                    <!--                      Subscribe-->
                    <!--                  </button>-->
                </div>
            </div>
        </div>
        <section class="bg-white border-b py-8">
            <div class="container mx-auto flex flex-wrap">
                <div class="w-full rounded bg-gray-600 p-4 flex place-content-center content-center justify-center my-4 mx-auto" style="max-width: 1080px;">
                    <font-awesome-icon class="fa-3x flex-grow text-amber-400 " icon="brake-warning"></font-awesome-icon>
                    <div class="flex-grow px-4">
                    <p class="text-white">
                        The Webinars below require General Awareness/Safety/Security Training as a prerequisite. You will automatically be enrolled in our Online General Awareness training, which must be completed prior to the start of your webinar training.
                    </p>
                    </div>
                </div>
                <webinar-detail-card v-for="(webinar, index) in webinars" :training="webinar" :key="index" :features="webinar.features" :options="webinar.options">
                    <template #date>
                        <div class="uppercase py-1 text-sm font-bold text-gray-500 tracking-widest"> {{ webinar.format }} · {{ webinar.date }}</div>
                    </template>
                    <template #icon>
                        <div class="text-center">
                            <font-awesome-icon fixed-width size="4x" class="text-gray-600" :icon="webinar.icon"></font-awesome-icon>
                        </div>
                    </template>
                    <template #title>
                        {{ webinar.name }}
                    </template>
                    <template #description>
                        {{ webinar.description }}
                    </template>
                </webinar-detail-card>
            </div>
        </section>
        <benefit-section :benefits="benefits"></benefit-section>
        <faq-section :faqs="faqs"></faq-section>
    </div>
</template>

<script>
import FaqSection from "@/components/FaqSection";
import WebinarDetailCard from "@/components/WebinarDetailCard";
import BenefitSection from "@/components/BenefitSection";
export default {
    name: 'Webinars',
    components: {
        BenefitSection,
        WebinarDetailCard,
        FaqSection,
    },
    data: () => ({
        webinars: [],
        devWebinars: [
            {
                format: 'Webinar',
                date: 'March 22-23',
                name: 'Dangerous Goods by Air',
                icon: 'plane-engines',
                price: {
                    unitAmount: 495,
                    stripePriceId: 'price_1KSvppKFycC7E4HwUFKggl46'
                },
                description: 'Get certified to prepare, ship, or handle dangerous goods transported by air in accordance with the latest IATA Dangerous Goods Regulations. This two-day webinar is ideal for both initial and recurrent training, and covers everything required for acceptance from major worldwide carriers including Fedex, UPS, and DHL',
                title: '',
                features: [
                    {
                        icon: 'diploma',
                        title: 'Certification',
                        description: 'IATA Certification'
                    },
                    {
                        icon: 'box-open',
                        title: 'Mode of Transport',
                        description: 'Air Shipping'
                    },
                    {
                        icon: 'calendar-clock',
                        title: 'Training Duration',
                        description: '2 Days'
                    }],
                options: [],
            },
            {
                format: 'Webinar',
                date: 'March 24',
                price: {
                    unitAmount: 395,
                    stripePriceId: 'price_1KT6ibKFycC7E4Hw8Lv9cOkl'
                },
                name: 'Dangerous Goods by Ocean',
                icon: 'container-storage',
                description: 'Get certified to prepare, ship, or handle dangerous goods transported by ocean vessel in accordance with the IMO IMDG Regulations.\n' +
                    '                        This one-day webinar is ideal for both initial and recurrent training. This is the regulatory compliance training required by all major\n' +
                    '                        ocean carriers and the US DOT. Includes online DOT required General Awareness/Safety/Security.',
                features: [
                    {
                        icon: 'diploma',
                        title: 'Certification',
                        description: 'IMDG Certification'
                    },
                    {
                        icon: 'box-open',
                        title: 'Mode of Transport',
                        description: 'Ocean Shipping'
                    },
                    {
                        icon: 'calendar-clock',
                        title: 'Training Duration',
                        description: '1 Day'
                    },
                ],
                options: [],
            },
            {
                format: 'Webinar',
                date: 'March 25',
                name: 'Dangerous Goods by Ground',
                icon: 'road',
                price: {
                    unitAmount: 395,
                    stripePriceId: 'price_1KT6j5KFycC7E4Hwucg0Sjol'
                },
                description: 'Get certified to prepare, ship, or handle dangerous goods transported by ground in accordance with DOT 49 CFR Regulations.\n' +
                    '                        This one-day webinar is ideal for both initial and recurrent training, and covers everything required for acceptance from\n' +
                    '                        major carriers including Fedex, UPS, and DHL. Includes online DOT required General Awareness/Safety/Security.',
                features: [
                    {
                        icon: 'diploma',
                        title: 'Certification',
                        description: 'DOT Certification'
                    },
                    {
                        icon: 'box-open',
                        title: 'Mode',
                        description: 'Ground Shipping'
                    },
                    {
                        icon: 'calendar-clock',
                        title: 'Training Duration',
                        description: '1 Day'
                    },
                ],
                options: [],
            },
            {
                format: 'Webinar',
                date: 'March 22-25',
                name: 'Multimodal Dangerous Goods Certification',
                icon: 'globe',
                price: null,
                description: 'Get certified to prepare, ship, or handle hazardous materials for multiple modes of transport and save on training. Includes online DOT required General Awareness/Safety/Security.',
                features: [
                    {
                        icon: 'diploma',
                        title: 'Certification',
                        description: 'Multiple'
                    },
                    {
                        icon: 'box-open',
                        title: 'Mode of Transport',
                        description: 'Multiple'
                    },
                    {
                        icon: 'calendar-clock',
                        title: 'Training Duration',
                        description: 'Varies'
                    },
                ],
                prices: [
                    {text: 'IMDG/DOT - $495', unitAmount: 495, stripePriceId: 'price_1KT6mYKFycC7E4Hw7IGqK07S', value: 'imdg'},
                    {text: 'IATA/DOT - $595', unitAmount: 595, stripePriceId: 'price_1KT6nQKFycC7E4HwMH9GUwEo', value: 'iata'},
                    {text: 'IATA/IMDG - $595', unitAmount: 595, stripePriceId: 'price_1KT6oDKFycC7E4Hw2Mazx6aW', value: 'dot'},
                    {text: 'IATA/IMDG/DOT - $695', unitAmount: 695, stripePriceId: 'price_1KT6ocKFycC7E4HwQd0Oupwq', value: 'multi'},
                ],
                options: [
                    {text: 'IMDG/DOT - $495', value: 'imdg'},
                    {text: 'IATA/DOT - $595', value: 'iata'},
                    {text: 'IATA/IMDG - $595', value: 'dot'},
                    {text: 'IATA/IMDG/DOT - $695', value: 'imdg'},
                ],
                stripePriceId: 'price_1KSvppKFycC7E4HwUFKggl46'
            }
        ],
        liveWebinars: [
            {
                format: 'Webinar',
                date: 'March 22-23',
                name: 'Dangerous Goods by Air',
                icon: 'plane-engines',
                price: {
                    unitAmount: 495,
                    stripePriceId: 'price_1KTQkGKFycC7E4HwGuwEAq5d'
                },
                description: 'Get certified to prepare, ship, or handle dangerous goods transported by air in accordance with the latest IATA Dangerous Goods Regulations. This two-day webinar is ideal for both initial and recurrent training, and covers everything required for acceptance from major worldwide carriers including Fedex, UPS, and DHL',
                title: '',
                features: [
                    {
                        icon: 'diploma',
                        title: 'Certification',
                        description: 'IATA Certification'
                    },
                    {
                        icon: 'box-open',
                        title: 'Mode of Transport',
                        description: 'Air Shipping'
                    },
                    {
                        icon: 'calendar-clock',
                        title: 'Training Duration',
                        description: '2 Days'
                    }],
                options: [],
            },
            {
                format: 'Webinar',
                date: 'March 24',
                price: {
                    unitAmount: 395,
                    stripePriceId: 'price_1KTQkgKFycC7E4Hwg8BYV6GW'
                },
                name: 'Dangerous Goods by Ocean',
                icon: 'container-storage',
                description: 'Get certified to prepare, ship, or handle dangerous goods transported by ocean vessel in accordance with the IMO IMDG Regulations.\n' +
                    '                        This one-day webinar is ideal for both initial and recurrent training. This is the regulatory compliance training required by all major\n' +
                    '                        ocean carriers and the US DOT. Includes online DOT required General Awareness/Safety/Security.',
                features: [
                    {
                        icon: 'diploma',
                        title: 'Certification',
                        description: 'IMDG Certification'
                    },
                    {
                        icon: 'box-open',
                        title: 'Mode of Transport',
                        description: 'Ocean Shipping'
                    },
                    {
                        icon: 'calendar-clock',
                        title: 'Training Duration',
                        description: '1 Day'
                    },
                ],
                options: [],
            },
            {
                format: 'Webinar',
                date: 'March 25',
                name: 'Dangerous Goods by Ground',
                icon: 'road',
                price: {
                    unitAmount: 395,
                    stripePriceId: 'price_1KTQlDKFycC7E4HwPYUhUAdb'
                },
                description: 'Get certified to prepare, ship, or handle dangerous goods transported by ground in accordance with DOT 49 CFR Regulations.\n' +
                    '                        This one-day webinar is ideal for both initial and recurrent training, and covers everything required for acceptance from\n' +
                    '                        major carriers including Fedex, UPS, and DHL. Includes online DOT required General Awareness/Safety/Security.',
                features: [
                    {
                        icon: 'diploma',
                        title: 'Certification',
                        description: 'DOT Certification'
                    },
                    {
                        icon: 'box-open',
                        title: 'Mode',
                        description: 'Ground Shipping'
                    },
                    {
                        icon: 'calendar-clock',
                        title: 'Training Duration',
                        description: '1 Day'
                    },
                ],
                options: [],
            },
            {
                format: 'Webinar',
                date: 'March 22-25',
                name: 'Multimodal Dangerous Goods Certification',
                icon: 'globe',
                price: null,
                description: 'Get certified to prepare, ship, or handle hazardous materials for multiple modes of transport and save on training. Includes online DOT required General Awareness/Safety/Security.',
                features: [
                    {
                        icon: 'diploma',
                        title: 'Certification',
                        description: 'Multiple'
                    },
                    {
                        icon: 'box-open',
                        title: 'Mode of Transport',
                        description: 'Multiple'
                    },
                    {
                        icon: 'calendar-clock',
                        title: 'Training Duration',
                        description: 'Varies'
                    },
                ],
                prices: [
                    {text: 'IMDG/DOT - $495', unitAmount: 495, stripePriceId: 'price_1KTQlWKFycC7E4HwHtWN3taX', value: 'imdg'},
                    {text: 'IATA/DOT - $595', unitAmount: 595, stripePriceId: 'price_1KTQleKFycC7E4HwmFNKO8Zs', value: 'iata'},
                    {text: 'IATA/IMDG - $595', unitAmount: 595, stripePriceId: 'price_1KTQliKFycC7E4HwjNOU2jyT', value: 'dot'},
                    {text: 'IATA/IMDG/DOT - $695', unitAmount: 695, stripePriceId: 'price_1KTQllKFycC7E4Hw1qhBGYAK', value: 'multi'},
                ],
                options: [
                    {text: 'IMDG/DOT - $495', value: 'imdg'},
                    {text: 'IATA/DOT - $595', value: 'iata'},
                    {text: 'IATA/IMDG - $595', value: 'dot'},
                    {text: 'IATA/IMDG/DOT - $695', value: 'imdg'},
                ],
                stripePriceId: 'price_1KSvppKFycC7E4HwUFKggl46'
            }
        ],
        benefits: [
            {
                icon: 'laptop',
                name: 'Interactive Virtual Training',
                description: 'Webinars offer an engaging, visual-learning approach with a live instructor\n'
            },
            {
                icon: 'shield-check',
                name: 'Industry-Accepted Certification',
                description: 'Regulation compliant and accepted by carriers worldwide including Fedex, UPS, and DHL.\n'
            },
            {
                icon: 'life-ring',
                name: 'After-Training Compliance Support',
                description: 'Trainees receive unlimited access to our experts for compliance support and questions.\n'
            }
        ],
        faqs: [
            {
                question: 'Do I need a copy of the regulations for my training?',
                answer: 'A copy of the current IATA Regulations, IMDG Code or 49CFR is required for certification in those webinars'
            },
            {
                question: 'What is the daily schedule for the webinars?',
                answer: 'We start at 9AM PST each day, and continue until 3PM PST with a one-hour break for lunch at 12PM PST. Most days will include a one-hour “homework” assignment.'
            },
            {
                question: 'What Topics are Covered in the Webinars?',
                answer: '<ul>' +
                    '<li>- A definition of “hazardous materials” and “dangerous goods”</li>' +
                    '<li>- Review of the nine classes of hazardous materials set out in the regulations</li>' +
                    '<li>- A detailed review of the shipping process for all classes and divisions except Radioactive Materials (Class 7)</li>' +
                    '<li>- Packaging and package selection (both UN packaging and non-UN packaging types)</li>' +
                    '<li>- Marking, labeling, and documentation requirements learned by preparing sample shipments</li>' +
                    '<li>- Safety, emergency response, and security awareness</li>' +
                    '<li>- The shipper’s responsibilities in the transportation process</li>' +
                    '<li>- Note: additional training is recommended for shippers of Class 1 (Explosives), Division 6.2 (Infectious Substances) and Class 9 (Lithium Batteries) as only an introduction to these hazard classes is provided.</li>' +
                    '</ul>'
            },
            {
                question: "Who Should Take This Course?",
                answer: 'This comprehensive course is designed for those who classify dangerous goods, select authorized packaging, mark and label packaging and/or sign and certify shipping documents.  It is also for employees with supervisory responsibility for such hazmat employees.'
            }

        ],
    }),
    methods: {
    },
    created() {
        if (process.env.NODE_ENV === 'production') {
            this.webinars = this.liveWebinars
        } else {
            this.webinars = this.devWebinars
        }
    }
}
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

.hero {
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2)),
    url('/src/assets/tdg-webinar-lp-hero.jpg');
    background-size: cover;
}
</style>
