<template>
  <div id="app">
      <simple-navbar></simple-navbar>
      <router-view></router-view>
      <default-footer></default-footer>
  </div>
</template>

<script>
import DefaultFooter from "@/components/DefaultFooter";
import SimpleNavbar from "@/components/SimpleNavbar";
export default {
    name: 'App',
    components: {
        SimpleNavbar,
        DefaultFooter,
    },
    data: () => ({
        value: 10,
        benefits: [
            {
                icon: 'laptop',
                name: 'Convenient Virtual Training',
                description: 'Webinars offer an engaging, visual-learning approach with a live instructor\n'
            },
            {
                icon: 'shield-check',
                name: 'Industry Compliant Certification',
                description: 'Regulation compliant and accepted by carriers worldwide including Fedex, UPS, and DHL.\n'
            },
            {
                icon: 'life-ring',
                name: 'After-Training Compliance Support',
                description: 'Trainees receive unlimited access to our experts for compliance support and questions.\n'
            }
        ],
        iataFeatures: [
            {
                icon: 'diploma',
                title: 'Certification',
                description: 'IATA Certification'
            },
            {
                icon: 'box-open',
                title: 'Mode of Transport',
                description: 'Air Shipping'
            },
            {
                icon: 'calendar-clock',
                title: 'Valid for',
                description: '2 Years'
            }
        ],
        imdgFeatures: [
            {
                icon: 'diploma',
                title: 'Certification',
                description: 'IMDG Certification'
            },
            {
                icon: '',
                title: 'Mode',
                description: 'Ocean Shipping'
            },
            {
                icon: '',
                title: 'Length',
                description: 'One-day'
            },
        ],
        dotFeatures: [
            {
                icon: '',
                title: 'Certification',
                description: 'DOT Certification'
            },
            {
                icon: '',
                title: 'Mode',
                description: 'Ground Shipping'
            },
            {
                icon: '',
                title: 'Length',
                description: 'One-day'
            },
        ],
        faqs: [
            {
                question: 'Do I need a copy of the regulations for my training?',
                answer: 'A copy of the current IATA Regulations or IMDG Regulations is required for certification in those webinars'
            }
        ]
    }),
    methods: {
      add () {
          this.value = this.value + 10
      }
    },
    created() {
    }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hero {
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6)),
    url('/src/assets/tdg-webinar-lp-hero.jpg');
    background-size: cover;
}
</style>
