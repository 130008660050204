<template>
    <div class="container shadow mx-auto rounded flex my-6 flex-wrap" style="max-width: 1080px;">
<!--        <div class="w-full bg-gray-200 md:w-1/3 p-6 flex flex-col align-center justify-center flex-grow flex-shrink text-center">-->
<!--            <slot name="icon"></slot>-->
<!--        </div>-->
        <div class="w-full md:w-2/3 flex flex-col flex-grow flex-shrink">
            <div class="flex-1 bg-white rounded-t rounded-b-none p-6">
                <div class="flex flex-wrap">
                    <p class="w-full text-gray-600 text-xs md:text-sm px-3">
                        <slot name="date"></slot>
                    </p>
                    <div class="w-full font-bold text-3xl text-gray-800 mb-6 px-3">
                        <slot name="title"></slot>
                    </div>
                    <div class="text-2xl font-bold text-teal-500 mb-3 px-3" style="line-height: 1">
                        <template v-if="price">
                            ${{ price.unitAmount }}
                            <span class=" ml-2 text-sm text-gray-600">/seat </span>
                        </template>
                        <template v-else>
                            <span class="text-black">Select Certification</span>
                        </template>
                    </div>
<!--                    <div class="w-full text-gray-600 text-xs md:text-sm mb-4 px-6">-->
<!--                        <slot name="subtitle"></slot>-->
<!--                    </div>-->
                    <p class="text-gray-600 text-base ml-3">
                        <slot name="description"></slot>
                    </p>
                </div>
            </div>
        </div>
        <div class="w-full md:w-1/3 p-3 flex flex-col flex-grow flex-shrink">
            <div class="flex-1 bg-white rounded-t rounded-b-none px-6">
                <div class="flex items-center justify-start">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li v-for="(feature, index) in features" v-bind:key="index" class="py-4 flex">
                            <div class="h-10 w-10 rounded-full">
                                <font-awesome-icon class="text-teal-500" :icon="feature.icon || 'road'"></font-awesome-icon>
                            </div>
                            <div class="ml-3">
                                <p class="text-sm font-medium text-gray-900">{{ feature.title }}</p>
                                <p v-if="feature.description" class="text-sm text-gray-500">{{ feature.description }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="w-full">
            <div class="px-6 mb-4 md:flex">
                <div class="my-2" v-if="training.prices && training.prices.length > 0" style="min-width: 250px;">
                    <multi-select
                        label="text"
                        :options="training.prices"
                        style="z-index: 1000"
                        max-height="307"
                        v-model="price"
                        :object="true"
                        placeholder="Certification"
                    ></multi-select>
                </div>
                <div style="min-width: 160px" class="my-2 md:m-2">
                    <multi-select
                        label="text"
                        value="value"
                        v-model="quantity"
                        :options="quantities"
                        max-height="307"
                        placeholder="Quantity"
                    ></multi-select>
                </div>
                <div class="my-2 md:order-3">
                    <button type="button" :disabled="disabled" @click="createCheckout" style="text-decoration: none" class="mx-auto w-full disabled:bg-gray-200 disabled:text-gray-700 bg-teal-500 text-white font-bold py-2 px-8 hover:shadow rounded focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                        Checkout
                    </button>
                </div>
            </div>
        </div>
<!--        <div class="w-full mt-auto bg-white rounded-b rounded-t-none px-3">-->
<!--            <div class="">-->
<!--                <div class="text-2xl my-3 px-6">-->
<!--                    $395 /seat-->
<!--                </div>-->
<!--                <div v-if="options && options.length > 0" style="min-width: 250px;">-->
<!--                    <multi-select-->
<!--                        mode="multiple"-->
<!--                        label="text"-->
<!--                        value="value"-->
<!--                        :options="options"-->
<!--                        style="z-index: 1000"-->
<!--                        max-height="307"-->
<!--                        placeholder="Choose Dates"-->
<!--                    ></multi-select>-->
<!--                </div>-->
<!--                <div style="min-width: 220px" class="mx-2">-->
<!--                    <multi-select-->
<!--                        label="text"-->
<!--                        value="value"-->
<!--                        v-model="quantity"-->
<!--                        :options="quantities"-->
<!--                        max-height="307"-->
<!--                        placeholder="Choose Quantity"-->
<!--                    ></multi-select>-->
<!--                </div>-->
<!--                <button type="button" :disabled="disabled" @click="createCheckout" style="text-decoration: none" class="mx-auto disabled:bg-gray-200 disabled:text-gray-700 lg:mx-0 bg-teal-500 text-white font-bold py-2 px-8 hover:shadow rounded focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">-->
<!--                    Checkout-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
export default {
    name: "WebinarDetailCard",
    components: {
    },
    data: () => ({
        loading: false,
        successURL: 'https://tdg.site/success',
        cancelURL: 'https://tdg.site/cancel',
        quantity: null,
        lineItems: [
            {
                price: 'price_1KSvppKFycC7E4HwUFKggl46',
                quantity: 1
            }
        ],
        price: null,
        stripeClient: null,
        publishableKey: null,
        quantities: [
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: '6', value: 6 },
            { text: '7', value: 7 },
            { text: '8', value: 8 },
            { text: '9', value: 9 },
            { text: '10', value: 10 },
            { text: '11', value: 11 },
            { text: '12', value: 12 },
            { text: '13', value: 13 },
            { text: '14', value: 14 },
            { text: '15', value: 15 },
            { text: '16', value: 16 },
            { text: '17', value: 17 },
            { text: '18', value: 18 },
            { text: '19', value: 19 },
            { text: '20', value: 20 },
        ]
    }),
    props: {
        features: {
            types: [Array, null],
            default: () => []
        },
        options: {
            types: [Array, null],
            default: () => []
        },
        training: {
            type: [Object],
            default: () => ({
                price: {},
                prices: []
            })
        }
    },
    computed: {
        disabled () {
            return this.quantity === null;
        }
    },
    watch: {
        price: {
            handler (val) {
                this.lineItems[0].price = val.stripePriceId
            },
            deep: true
        },
        quantity (val) {
            this.lineItems[0].quantity = val
        }
    },
    methods: {
        async createCheckout () {
            const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
            await stripe.redirectToCheckout({
                mode: 'payment',
                successUrl: this.successURL,
                cancelUrl: this.cancelURL,
                lineItems: this.lineItems,
            })
        }
    },
    async created() {
        if (this.training.price) {
            this.price = this.training.price
        }
        if (process.env.NODE_ENV === 'production') {
            this.successURL = 'https://catalog.dgtraining.com/success'
            this.cancelURL = 'https://catalog.dgtraining.com/webinars'
        } else {
            this.successURL = 'https://tdg.site/success'
            this.cancelURL = 'https://tdg.site/webinars'
        }
    }
}
</script>

<style scoped>

</style>
