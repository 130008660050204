<template>
    <div class="container">
        Terms
    </div>
</template>

<script>
export default {
    name: "Terms"
}
</script>

<style scoped>

</style>
