<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <nav class="bg-white shadow">
        <div class="mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex flex-grow justify-between align-middle">
                    <div class="flex items-center">
                        <router-link to="/">
                        <img class="block lg:hidden h-8 w-auto" src="https://tdg.s3.us-east-2.amazonaws.com/tdg-site+(3).png" alt="Workflow">
                        <img class="hidden lg:block h-8 w-auto" src="https://tdg.s3.us-east-2.amazonaws.com/tdg-site+(3).png" alt="Workflow">
                        </router-link>
                    </div>
                    <div class="flex-grow w-full justify-end flex align-middle py-2">
                        <button class="rounded py-2 px-2">
                            <font-awesome-icon icon="headset"></font-awesome-icon>
                            <span class="ml-2 font-medium">800-949-4834</span></button>
                    </div>
<!--                    <div class="hidden border text-right flex-grow justify-end sm:ml-6 sm:flex sm:space-x-8">-->
<!--                        &lt;!&ndash; Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" &ndash;&gt;-->
<!--                        <router-link :to="{path: '/'}" class="border-teal-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"> Home </router-link>-->
<!--                        <router-link to="/login" class="border-teal-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"> Login </router-link>-->
<!--                        <router-link to="/webinars" class="border-teal-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"> Webinars </router-link>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </nav>

</template>

<script>

export default {
    name: 'SimpleNavbar',
    components: {
    },
}
</script>
