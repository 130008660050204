<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="py-8 md:py-16 bg-teal-500">
        <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2 class="mx-auto text-3xl font-extrabold text-white text-center mb-8">What's Included</h2>
            <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
                <div v-for="(benefit, index) in benefits" v-bind:key="index">
                    <dt>
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-teal-700 shadow text-amber-500">
                            <font-awesome-icon size="lg" :icon="benefit.icon"></font-awesome-icon>
                        </div>
                        <p class="mt-5 text-lg leading-6 font-medium text-white">{{ benefit.name }}</p>
                    </dt>
                    <dd class="mt-2 text-base text-gray-100">
                        {{ benefit.description }}
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script>
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/vue/outline'

const features = [
    {
        name: 'Competitive exchange rates',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: GlobeAltIcon,
    },
    {
        name: 'No hidden fees',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: ScaleIcon,
    },
    {
        name: 'Transfers are instant',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: LightningBoltIcon,
    },
]

export default {
    name: 'BenefitSection',
    props: {
        benefits: {
            types: [Array],
            default: () => []
        }
    },
    setup() {
        return {
            features,
        }
    },
}
</script>
