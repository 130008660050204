import { createRouter, createWebHistory } from 'vue-router'
import Home from "@/pages/Home";
import Terms from "@/pages/Terms";
import Login from "@/pages/Login";
import Webinars from "@/pages/Webinars";
import Register from "@/pages/Register";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Webinars.vue')
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Terms.vue')
    },
    {
        path: '/webinars',
        name: 'webinars',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Webinars.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Register.vue')
    },
    {
        path: '/success',
        name: 'success',
        component: () => import(/* webpackChunkName: "success" */ '../pages/Success.vue')
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
})
export default router
